import { PLAYERHASFINISHED, STARTPLAYER } from './actions';

export interface PlayerState {
  playerHasFinished: boolean;
}

const initialPlayerState = {
  playerHasFinished: false,
};

export const playerReducer = (state: PlayerState = initialPlayerState, action) => {
    switch (action.type) {
      case PLAYERHASFINISHED:
        return {
          ...state, 
          playerHasFinished: true
        };
      default: return state;
    }
};

export interface StartPlayerState {
  startPlayer: boolean;
}

const initialStartPlayerState = {
  startPlayer: false
}

export const startPlayerReducer = (state: StartPlayerState = initialStartPlayerState, action) => {
    switch (action.type) {
      case STARTPLAYER:
        return {
          ...state, 
          startPlayer: true
        };
      default: return state;
    }
}