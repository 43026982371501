export const PLAYERHASFINISHED = "PLAYERHASFINISHED";
export const STARTPLAYER = "STARTPLAYER";

export const playerHasFinished = () => {
    return {
        type: PLAYERHASFINISHED
    };
};

export const startPlayer = () => {
    return {
        type: STARTPLAYER
    };
};


